<template>
  <div class="big">
    <div class="container">
      <div class="flex between">
        <div class="searchbox">
          <div>
            <span>手机号</span>
            <el-input placeholder="请输入"
                      v-model="phone"
                      style="width:180px;margin:0 15px 0 5px"
                      @input="search"
                      clearable></el-input>
          </div>
          <div>
            <span>角色</span>
            <el-select @change="search"
                       v-model="group_id"
                       clearable
                       placeholder="请选择"
                       class="input"
                       style="width:180px;margin:0 15px 0 5px">
              <el-option :label="role.group_name"
                         :value="role.group_id"
                         v-for="(role,index) in roleList"
                         :key="index"></el-option>
            </el-select>
            <el-button type="primary"
                       @click="search">查询</el-button>
          </div>
        </div>
        <div>
          <el-button type="primary"
                     style="width:124px;"
                     icon="el-icon-plus"
                     @click="AddUser"
                     v-if="module_id_array.includes('29')||module_id_array.includes('999')">添加用户</el-button>
        </div>
      </div>

      <div style="border: solid 1px #c9cbd6;margin-top:16px">
        <el-table ref="multipleTable"
                  :data="tableData"
                  tooltip-effect="dark"
                  style="width: 100%;min-width:1000px;"
                  :header-cell-style="{background:'#F5F5F5'}">
          <el-table-column prop="phone"
                           label="用户名"></el-table-column>
          <el-table-column prop="group_name"
                           label="角色"></el-table-column>
          <el-table-column prop="desc"
                           label="描述"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span v-if="scope.row.user_status">正常</span>
              <span v-else
                    style="color:#ff514b">锁定</span>
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button @click="editAdmin(scope.row)"
                         type="text"
                         v-if="scope.row.admin_id!=1&&(module_id_array.includes('30')||module_id_array.includes('999'))&&(scope.row.admin_id!=scope.row.creator_id)">
                编辑</el-button>
              <el-button @click="editStatus(scope.row.admin_id,0)"
                         type="text"
                         v-if="scope.row.user_status&&scope.row.admin_id!=1&&(module_id_array.includes('31')||module_id_array.includes('999'))">
                锁定</el-button>
              <el-button @click="editStatus(scope.row.admin_id,1)"
                         type="text"
                         v-if="!scope.row.user_status&&scope.row.admin_id!=1&&(module_id_array.includes('31')||module_id_array.includes('999'))">
                解锁</el-button>
              <el-button @click="resetPwd(scope.row.admin_id)"
                         type="text"
                         v-if="module_id_array.includes('32')||module_id_array.includes('999')">重置密码</el-button>
              <el-button @click="delUser(scope.row.admin_id)"
                         type="text"
                         v-if="scope.row.admin_id!=1&&(module_id_array.includes('40')||module_id_array.includes('999'))">
                删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div>
        <el-pagination @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       :current-page="currentPage"
                       :page-sizes="[10, 20, 50, 100]"
                       :page-size="10"
                       layout="total, sizes, prev, pager, next, jumper"
                       :total="totalNum"
                       style="margin:30px 0 40px 0"></el-pagination>
      </div>
    </div>
    <!-- 添加编辑用户 -->
    <el-dialog :title="title"
               :visible.sync="adduser"
               width="580px"
               :close-on-click-modal="false">
      <div class="myform">
        <el-form :model="ruleForm"
                 :rules="rules"
                 ref="ruleForm"
                 label-width="70px"
                 class="demo-ruleForm">
          <el-form-item label="手机号"
                        prop="phone">
            <el-input v-model="ruleForm.phone"
                      placeholder="请输入手机号"
                      style="width:280px"></el-input>
          </el-form-item>

          <el-form-item label="密码"
                        prop="pwd"
                        v-if="title=='添加用户'">
            <el-input v-model="ruleForm.pwd"
                      maxlength="10"
                      style="width:280px"
                      placeholder="请输入6-10位密码"></el-input>
          </el-form-item>
          <el-form-item label="角色"
                        prop="group_id">
            <el-select v-model="ruleForm.group_id"
                       placeholder="请选择角色"
                       class="input"
                       style="width:280px">
              <el-option :label="role.group_name"
                         :value="role.group_id"
                         v-for="(role,index) in roleList"
                         :key="index"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="描述">
            <el-input v-model="ruleForm.desc"
                      placeholder="请输入20位以内汉字"
                      maxlength="20"
                      style="width:280px"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="submit('ruleForm')">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      level_three: "",
      currentPage: 1,
      pagesize: 10,
      totalNum: 0,
      tableData: [],
      roleList: [],
      phone: "",
      group_id: "",
      adduser: false,
      title: "添加用户",

      ruleForm: {
        phone: "",
        pwd: "",
        group_id: "",
        desc: "",
      },
      rules: {
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern: /^(1[123456789])\d{9}$/,
            message: "请输入正确的手机号码",
            trigger: "blur",
          },
        ],
        pwd: [
          { required: true, message: "请输入6-20位密码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9]{6,20}$/,
            message: "请输入6-8位数字或字母",
            trigger: "blur",
          },
        ],
        group_id: [{ required: true, message: "请选择角色", trigger: "blur" }],
      },
      module_id_array: window.utils.storage.getter("module_id_array") || [],
    };
  },

  methods: {
    //获取列表
    getList() {
      let data = {
        page: this.currentPage,
        pageNumber: this.pagesize,
        phone: this.phone,
        group_id: this.group_id,
      };
      this.axios.post("/store/Admin/adminList", data).then((res) => {
        if (res.code == 0) {
          this.tableData = res.data.list;
          this.totalNum = res.data.total;
        }
      });
    },
    //获取角色列表
    getAdminGroup() {
      this.axios
        .post("/store/Admin/getAdminGroupByList")
        .then((res) => {
          if (res.code == 0) {
            this.roleList = res.data.list;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //每页数据的条数
    handleSizeChange(size) {
      this.pagesize = size;
      this.getList();
    },
    //当前第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
      this.getList();
    },
    //搜索
    search() {
      this.currentPage = 1;
      this.getList();
    },
    //添加用户按钮
    AddUser() {
      this.title = "添加用户";
      this.ruleForm = {
        phone: "",
        group_id: "",
        desc: "",
        pwd: "",
      };
      this.adduser = true;
    },
    //编辑用户
    editAdmin(row) {
      this.title = "编辑用户";
      this.ruleForm = {
        phone: row.phone,
        group_id: Number(row.group_id),
        admin_id: row.admin_id,
        desc: row.desc,
      };
      this.adduser = true;
    },
    //添加/编辑 用户
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.title == "添加用户") {
            let data = {
              phone: this.ruleForm.phone,
              desc: this.ruleForm.desc,
              group_id: this.ruleForm.group_id,
              pwd: this.ruleForm.pwd,
            };
            this.axios.post("/store/Admin/addAdmin", data).then((res) => {
              if (res.code == 0) {
                this.$message.success("添加成功");
                this.adduser = false;
                this.getList();
              }
            });
          } else {
            let data = {
              phone: this.ruleForm.phone,
              desc: this.ruleForm.desc,
              group_id: this.ruleForm.group_id,
              admin_id: this.ruleForm.admin_id,
            };
            this.axios.post("/store/Admin/editAdmin", data).then((res) => {
              if (res.code == 0) {
                this.$message.success("修改成功");
                this.adduser = false;
                this.getList();
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    //锁定或者解锁系统用户
    editStatus(admin_id, status) {
      this.axios
        .post("/store/Admin/editAdminStatus", { admin_id, status })
        .then((res) => {
          if (res.code == 0) {
            this.$message.success("成功修改用户状态");
            this.getList();
          }
        });
    },
    //重置密码
    resetPwd(admin_id) {
      this.$confirm("您确定重置密码为：123456？", "重置密码", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.axios
            .post("/store/Admin/editAdminPassword", { admin_id })
            .then((res) => {
              if (res.code == 0) {
                this.$message.success("成功重置密码");
                this.getList();
              }
            });
        })
        .catch(() => { });
    },
    //删除
    delUser(admin_id) {
      this.$confirm("您确定删除该账号？", "提示消息", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(() => {
          this.axios.post("/store/Admin/delAdmin", { admin_id }).then((res) => {
            if (res.code == 0) {
              this.$message.success("删除账号成功");
              if (!(this.tableData.length - 1) % this.pagesize) {
                this.currentPage =
                  this.currentPage == 1 ? 1 : this.currentPage - 1;
              }
              this.getList();
            }
          });
        })
        .catch(() => { });
    },
  },

  created() {
    this.getList();
    this.getAdminGroup();
  },
};
</script>

<style lang="scss" scoped>
.myform {
  width: 380px;
  padding-top: 20px;
  margin: 0 auto;
}
</style>